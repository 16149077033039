/* BLUE: #254D99 */
/* PURPLE: #AD66CF */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
    overflow-x: hidden;
}

body {
    font-family: "Be Vietnam Pro", sans-serif;
    background-color: #FEFEFE;
}

body p {
    color: #787878;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1170px;
    }
}

.header-top {
    background-color: #fff;
}

.header-top-announcement {
    font-size: 16px;
    font-weight: 500;
}

.navbar-menu {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.navbar-menu li {
    margin: auto;
    text-transform: uppercase;
}

.navbar-menu a {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .3px;
    color: #000000;
    text-decoration: none;
}

.homepage-about-content {
    margin-top: 75px;
}

.homepage-section-title {
    font-weight: 500;
    font-size: 32px;
}

.homepage-about-icon {
    background-color: rgba(37, 77, 153, 0.1);
    width: 70px;
    height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.homepage-about-items {
    margin-top: 75px;
}

.homepage-about-card {
    background-color: rgba(233, 237, 245, 0.2);
    padding: 35px 25px;
    border: 1px solid rgba(233, 237, 245, 0.8);
    border-radius: 5px;
}

.homepage-about-image {
    content: "";
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
}

.homepage-global-content {
    margin-top: 100px;
    background-color: #f8f8f8;
    padding: 75px 0;
}

.homepage-global-icon {
    background-color: #fff;
    width: fit-content;
    padding: 15px;
    border-radius: 50%;
    position: relative;
}

.homepage-global-icon::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 3px;
    background-color: #fff;
    right: 100%;
    top: 50%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.btn-primary-v2 {
    background: linear-gradient(90deg, rgba(37, 77, 153, 1) 0%, rgba(173, 102, 207, 1) 100%);
    color: #fff;
    padding: 10px 30px;
}

.btn-primary-v2:hover {
    color: #fff;
}

.homepage-why-nova-content {
    padding: 75px 0;
    background: linear-gradient(90deg, rgba(37, 77, 153, .8) 0%, rgba(173, 102, 207, .8) 100%);
    /* background-image: url("./assets/img/background-1.jpg"); */
    background-size: cover;
    background-position: center;
}

.banner-background-image {
    content: "";
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner-text-container {
    width: 60%;
}

.banner-background-image h3,
.banner-background-image h1 {
    color: #ffffff;
}

.banner-background-image h1 {
    font-size: 60px;
}

.banner-background-image h3 {
    letter-spacing: .3px;
    font-size: 26px;
    line-height: 42px;
    font-weight: 400;
}

.homepage-customer-review {
    padding: 75px 0;
}

.homepage-customer-review-card {
    background-color: #fff;
    padding: 42px;
    border-radius: 5px;
    box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.06);
    border: 1px solid #efefef;
    height: 327px;
}

.homepage-review-image {
    position: absolute;
    top: 35%;
    right: 0;
}

.homepage-customer-review .slick-prev:before,
.homepage-customer-review .slick-next:before {
    display: none;
}

.quote {
    position: absolute;
    right: 30%;
    top: 5px;
    opacity: .2;
}

.homepage-why-nova-content .slick-dots {
    top: -85px !important;
    right: 45px !important;
    width: fit-content !important;
}

.homepage-why-nova-card {
    background: rgba(255, 255, 255, 0.29);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.7px);
    -webkit-backdrop-filter: blur(7.7px);
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 30px 25px;
}

.homepage-why-nova-content .slick-dots li.slick-active button:before {
    color: #ffffff;
    opacity: .9;
}

.homepage-why-nova-content .slick-dots li button:before {
    color: #ffffff;
}

.homepage-why-nova-card h5 {
    color: #ffffff;
    font-size: 24px;
}

.homepage-why-nova-card p {
    font-weight: 300;
    font-size: 15px;
    color: #ffffff;
}

.homepage-why-nova-content .slick-prev:before,
.homepage-why-nova-content .slick-next:before {
    display: none;
}

.cta-content {
    margin-top: 75px;
}

.cta-content-container {
    background: linear-gradient(90deg, rgba(37, 77, 153, 1) 0%, rgba(173, 102, 207, 1) 100%);
    border-radius: 10px;
    padding: 75px;
}

.cta-image {
    position: absolute;
    right: 75px;
    bottom: 0;
    width: 40%;
}

.cta-content h5 {
    color: #ffffff;
}

.cta-content p {
    color: #cacaca;
    font-weight: 300;
}

.footer-content {
    margin-top: 75px;
    background-color: #F7F7F7;
    color: #ffffff;
    padding: 50px 0 115px;
    font-weight: 300;
    font-size: 15px;
}

.footer-content h5 {
    color: #000;
}

.footer-content a,
.footer-content li {
    color: #707070;
    font-weight: 300;
    text-decoration: none;
    font-size: 15px;
    margin-bottom: 5px;
}

.footer-content ul {
    list-style: none;
    padding: 0;
}

.footer-card {
    background-color: #fff;
    padding: 30px 20px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}

.footer-apps-item {
    background-color: #9FC037;
    padding: 10px;
    height: 50px;
    width: 50px;
    margin: 0 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
}

.footer-apps-item:hover {
    scale: 1.05;
}

.footer-card .footer-apps-item:first-child {
    background-color: #898989;
}

.footer-bottom {
    border-top: 1px solid #e5e5e5;
    padding-top: 25px;
    color: #707070;
    font-size: 14px;
    letter-spacing: .2px;
}

.subpage-banner-content {
    /* background-image: url("https://wp1.themevibrant.com/newwp/fxvibe/wp-content/themes/fxvibe//assets/images/background/page-title.jpg"); */
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.subpage-banner-title {
    color: #ffffff;
    font-size: 52px;
    font-weight: 600;
}

.subpage-banner-pagination {
    position: absolute;
    color: #ffffff;
    bottom: 25px;
    left: 0;
    font-size: 14px;
}

.subpage-banner-pagination a {
    color: #aaa;
    text-decoration: none;
}

.subpage-banner-pagination a:hover {
    text-decoration: underline;
}

.about-items-content {
    margin-top: 50px;
}

.about-item-card {
    padding: 45px 25px 25px;
    font-weight: 300;
    box-shadow: 0px 4px 50px 10px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
}

.about-items-container {
    margin-top: 75px;
}

.about-item-card-icon {
    position: absolute;
    background: radial-gradient(circle, rgba(173, 102, 207, 1) 20%, rgba(37, 77, 153, 1) 100%);
    top: -25px;
    left: 25px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.col-lg-6:nth-child(even) .about-item-card {
    margin-top: 3rem;
}

.ctav2-content {
    margin-top: 100px;
    background-color: #264d99;
    height: 175px;
    border-radius: 10px;
}

.ctav2-image {
    position: absolute;
    left: 50%;
    bottom: -115px;
    transform: translate(-50%);
}

.ctav2-text {
    font-size: 34px;
    color: #ffffff;
}

.about-mission-vision-content {
    margin-top: 100px;
}

.about-mission-vision-content .nav-pills .nav-link {
    font-size: 18px;
    letter-spacing: .2px;
    padding: 10px 30px;
    color: #264d99;
}

.about-mission-vision-content .nav-pills .nav-link.active {
    background-color: #ffffff;
    font-size: 18px;
    color: #264d99;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.about-mission-vision-content .tab-content {
    box-shadow: 0px 0px 200px 0px rgba(0, 0, 0, 0.10);
}

.investment-items-content {
    margin-top: 75px;
}

.investment-items-content .nav-pills {
    background-color: #191919;
    border-radius: 25px 0 0 25px;
    padding: 50px 100px 50px 50px;
    min-height: 493px;
}

.investment-items-content .nav-link {
    margin-bottom: 10px;
    text-align: start;
    color: #ffffff;
    padding: 18px 35px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
}

.investment-items-content .nav-link:last-child {
    margin-bottom: 0;
}

.investment-items-content .nav-pills .nav-link.active {
    background: linear-gradient(90deg, rgba(38, 77, 153, 1) 0%, rgba(166, 101, 204, 1) 50%, rgba(38, 77, 153, 1) 100%);
    width: 150%;
    border-radius: 50px;
    z-index: 12;
    position: relative;
}

.investment-items-content .nav-pills .nav-link.active::after {
    content: "";
    width: 50px;
    height: 50px;
    background-image: url("./assets/icons/chevron-purple.svg");
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 50%;
}

.investment-items-content .tab-content {
    background-color: #F7F7F7;
    padding: 25px 75px;
    border-radius: 0 25px 25px 0;
}

.investment-item-image {
    content: "";
    width: 100%;
    height: 200px;
    /* background-image: url("https://nova-markets.com/static/media/1.a10b0d2c.jpeg"); */
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.promotions-content {
    margin-top: 75px;
}

.promotions-card {
    margin-bottom: 75px;
}

.promotions-card-title {
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    bottom: -30px;
    width: 100%;
    text-align: center;
    padding: 20px 15px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .2px;
    transition: all .3s ease;
}

.promotions-card:hover .promotions-card-title {
    scale: 1.05;
}

.promotions-card img {
    position: relative;
}

.promotions-overlay {
    position: absolute !important;
    right: 16px;
    bottom: 35px;
    width: 91%;
    opacity: .8;
    transition: all .7s ease;
}

.promotions-card:hover .promotions-overlay {
    opacity: 0;
}

.promotion-detail-item-content {
    margin-top: 75px;
}

.trading-platform-content {
    margin-top: 75px;
}

.trading-platform-image {
    content: "";
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 30px 0;
    border-radius: 5px;
}

.trading-platform-card {
    border-radius: 5px;
}

.trading-platform-card-header {
    background: linear-gradient(90deg, rgba(38, 77, 153, .9) 0%, rgba(166, 101, 204, .7) 100%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
}

.trading-platform-card-header h5 {
    font-size: 18px;
}

.trading-platform-card-header span {
    font-size: 28px;
    color: rgb(255, 255, 255, .4);
}

.trading-platform-card-body {
    padding: 20px;
}

.advantages-content {
    margin-top: 75px;
}

.advantages-item-card-img {
    content: "";
    width: 280px;
    height: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}

.advantages-item-count {
    position: absolute;
    left: 0;
    top: -20px;
    font-size: 120px;
    opacity: .08;
}

.contact-form-content {
    margin-top: 75px;
}

.contact-form-content label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
}

.contact-form-content .form-control {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    font-size: 15px;
    padding: 10px 15px;
}

.contact-form-content .form-control::placeholder {
    font-size: 13px;
    color: #c4c4c4;
}

.contact-form-content .message {
    width: 100%;
    height: 210px;
}

.contact-form-card {
    padding: 30px;
    text-align: center;
}

.mobile-header {
    display: none;
}

.about-items-title {
    font-size: 42px;
}

.header-top-account a {
    color: #787878;
}

.register-form-content {
    margin-top: 75px;
}

.register-form-content label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
    text-align: start !important;
}

.register-form-content .form-control {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    font-size: 15px;
    padding: 10px 15px;
}

.register-form-content .form-control::placeholder {
    font-size: 13px;
    color: #c4c4c4;
}

.register-form-content .message {
    width: 100%;
    height: 210px;
}

.banner-content .swiper-pagination-bullet-active {
    background: #ffffff;
}

.banner-content .swiper-pagination-bullet {
    background: #ffffff;
}

.language-toggle-btn {
    background-color: transparent;
    border: none;
    font-size: 15px;
}

.register-form-content .text-danger,
.contact-form-content .text-danger {
    font-size: 13px;
    margin-top: 3px;
}

.navbar-menu .active {
    color: #264d99;
}

.social-media-icon {
    background: linear-gradient(90deg, rgba(37, 77, 153, 1) 0%, rgba(173, 102, 207, 1) 100%);
    width: 42px;
    height: 42px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}

.fixed-register-form-content {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
}

.fixed-register-form-content-btn {
    position: fixed;
    bottom: 30px;
}

.fixed-register-form-content-btn button {
    padding: 15px 0;
    font-size: 18px;
}

.fixed-register-form-content {
    margin-top: 75px;
}

.fixed-register-form-content label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
    text-align: start !important;
}

.fixed-register-form-content .form-control {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    font-size: 15px;
    padding: 10px 15px;
}

.fixed-register-form-content .form-control::placeholder {
    font-size: 13px;
    color: #c4c4c4;
}

.register-platforms-content {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(166, 101, 204, 1) 0%, rgba(38, 77, 153, 1) 85%);
    border-radius: 10px;
    padding: 35px 45px;
    text-align: center;
    color: #ffffff;

}

.register-platforms-content-icon {
    background-color: #fff;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
}

.register-platforms-content-icon:hover {
    scale: 1.05;
}

@media (max-width: 992px) {
    .header-content {
        display: none;
    }

    .homepage-about-content {
        margin-top: 50px !important;
    }

    .mobile-header {
        display: block;
        padding: 15px 5px;
    }

    .mobile-navigation {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mobile-navigation li {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #efefef;
    }

    .mobile-navigation a {
        font-size: 17px;
        text-decoration: none;
        color: #000;
        font-weight: 500;
        width: 100%;
        padding: 10px 5px;
    }

    .mobile-navigation .active {
        color: #264d99;
    }

    .banner-background-image {
        height: 400px !important;
    }

    .homepage-about-card {
        text-align: center !important;
    }

    .homepage-global-content {
        margin-top: 75px;
    }

    .homepage-global-button button {
        width: 100%;
    }

    .homepage-customer-review {
        padding: 50px 0 !important;
    }

    .homepage-customer-review-card {
        height: auto !important;
    }

    .quote {
        opacity: .05;
    }

    .cta-content {
        margin-top: 50px;
    }

    .cta-content-container {
        padding: 35px;
        border-radius: 0;
        text-align: center;
    }

    .cta-image {
        display: none;
    }

    .footer-content {
        margin-top: 50px;
    }

    .subpage-banner-content {
        height: 250px;
    }

    .subpage-banner-title {
        font-size: 32px;
    }

    .about-items-content {
        margin-top: 35px;
    }

    .about-item-card {
        margin-top: 3rem !important;
    }

    .about-items-container {
        margin-top: 25px;
    }

    .about-items-title {
        font-size: 28px;
    }

    .about-item-btn button {
        width: 100%;
    }

    .ctav2-content {
        height: auto;
        margin-top: 135px;
    }

    .ctav2-image {
        bottom: -50px;
    }

    .ctav2-image img {
        width: 125px;
        height: 125px;
    }

    .ctav2-text {
        margin-top: 50px;
        font-size: 24px;
        padding: 15px 25px 25px;
        text-align: center;
    }

    .about-mission-vision-content {
        margin-top: 75px;
    }

    .promotions-card {
        margin-bottom: 65px;
    }

    .promotion-detail-item-content {
        margin-top: 42px;
    }

    .trading-platform-content {
        margin-top: 42px;
    }

    .trading-platform-card {
        height: auto !important;
        margin-bottom: 30px;
    }

    .advantages-item-card-img {
        width: 200px;
        height: 200px;
    }

    .advantages-item {
        text-align: center;
    }

    .advantages-item-card {
        display: flex;
        justify-content: center;
    }

    .advantages-item-count {
        top: 10px;
    }

    .advantages-content {
        margin-top: 42px;
    }

    .advantages-item-reverse {
        flex-direction: column-reverse;
    }

    .banner-text-container {
        width: 100%;
    }

    .banner-background-image h1 {
        font-size: 36px;
    }

    .banner-background-image h3 {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 32px;
    }

    .investment-items-content .nav.flex-column.nav-pills {
        width: 100% !important;
    }

    .investment-mobile {
        flex-direction: column;
    }

    .investment-items-content .nav-pills {
        min-height: auto;
        padding: 25px 103px 25px 20px;
        border-radius: 10px 10px 0 0;
    }

    .investment-items-content .nav-link {
        padding: 15px 25px;
    }

    .investment-items-content .nav-pills .nav-link.active::after {
        top: 5px;
    }

    .investment-items-content .tab-content {
        padding: 20px;
        border-radius: 0 0 10px 10px;
    }

    .investment-items-content {
        margin-top: 50px;
    }

    .homepage-why-nova-content {
        padding: 50px 0;
    }

    .homepage-why-nova-content .slick-dots {
        top: auto !important;
        left: 50%;
        right: auto !important;
        transform: translate(-50%);
    }

    .homepage-section-title {
        font-size: 26px;
        text-align: center;
    }

    .footer-content {
        text-align: center;
    }

    .contact-form-content {
        margin-top: 50px;
    }

    .register-form-content {
        margin-top: 50px;
    }

    .fixed-register-form-content-btn,
    .fixed-register-form-content {
        display: none;
    }

    .register-form-content-mobile {
        flex-direction: column-reverse;
    }

    .register-platforms-content {
        margin-top: 40px;
        padding: 30px 15px;
    }
}